import React, {useEffect, useState} from "react";
import {
    Box, Button,
    Checkbox,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Modal, TextField,
    Typography
} from "@mui/material";
import {style} from "./Style";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Chip from '@mui/material/Chip';

interface ModelsModalProps {
    open: boolean;
    handleClose: () => void,
    models: string[],
    selectedModels: string[],
    setSelectedModels: (selectedBrands: string[]) => void;
}

const ModelsModal: React.FC<ModelsModalProps> = ({open, handleClose, models, selectedModels, setSelectedModels})=>{
    const [checked, setChecked] = React.useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        setChecked(selectedModels);
    }, [selectedModels]);

    const [filteredModels, setFilteredModels] = useState<string[]>(models);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    useEffect(() => {
        // Фильтрация марок на основе поискового запроса
        const filtered = models.filter(option =>
            option.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredModels(filtered);
    }, [searchTerm, models]);

    const handleAccept = () => {
        setSelectedModels(checked);
        handleClose();
    };

    const handleReset = () => {
        setChecked([]);
        setSearchTerm('')
        setSelectedModels([]);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

   return (
        <Modal open={open}>
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                    <Typography sx={{ fontWeight: 'bold', textAlign: 'left' }}>Модели</Typography>
                    <Box display="flex" alignItems="center">
                        {checked.length > 0 && (
                            <Typography onClick={handleReset} sx={{ cursor: 'pointer', marginRight: '8px', fontWeight: 'bold', color: 'blue' }}>
                                Сбросить
                            </Typography>
                        )}
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <TextField
                    label="Поиск"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ marginBottom: '16px' }}
                />
                <Box sx={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {checked.map((model, index) => (
                        <Chip
                            key={index}
                            label={model}
                            onDelete={() => setChecked(checked.filter((item) => item !== model))}
                            sx={{ margin: '4px' }}
                        />
                    ))}
                </Box>
                <List sx={{ maxHeight: 300, overflow: 'auto' }}>
                    {filteredModels.map((option, index) => {
                        const labelId = `checkbox-list-label-${index}`;
                        const isSelected = checked.indexOf(option) !== -1;

                        return (
                            <ListItem
                                key={index}
                                role={undefined}
                                dense
                                button
                                onClick={() => {
                                    if (!isSelected && checked.length >= 20) {
                                        alert("Максимум 20 моделей");
                                        return;
                                    }
                                    handleToggle(option)();
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={isSelected}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={option} />
                            </ListItem>
                        );
                    })}
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '16px' }}>
                    <Button variant="contained" onClick={handleAccept}>Применить</Button>
                </Box>
            </Box>
        </Modal>
   );
}

export default ModelsModal;